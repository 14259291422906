import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import Link from 'components/base/Link';
import CollectionCatalog from 'components/modules/CollectionCatalog';
import IconSearch from 'svg/icn-search';

const Catalog = ({ data }) => {
  const itemsWithImages = data.allMalItem.nodes
    .filter(item => item.displayImage)
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  const itemsWithoutImages = data.allMalItem.nodes
    .filter(item => !item.displayImage)
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  const items = itemsWithImages.concat(itemsWithoutImages);
  return (
    <Layout pageTitle="Catalog">
      <article className="CollectionTabs container container--thin container--boxed body-copy-only">
        <ul className="block flex">
          <Link
            className="CollectionTabs__search db w-100 w-50-md br pointer tc pv2 relative body-subheader flex flex-column items-center"
            shouldAnimate={false}
            fromTaxon={false}
            to="/collection/search"
          >
            <IconSearch className="CollectionTabs__search-title" />
          </Link>
          <Link
            className="CollectionTabs__index db w-100 w-50-md pointer tc pv2 relative body-subheader flex flex-column items-center"
            shouldAnimate={false}
            fromTaxon={false}
            to="/collection/indexed"
          >
            <span className="CollectionTabs__index-title">Index</span>
          </Link>
        </ul>
      </article>
      <CollectionCatalog
        className="CollectionCatalog mb7"
        taxa={data.allMalTaxa.nodes
          .filter(item => {
            return item.name.split('__').length < 2;
          })
          .sort((a, b) => b.itemsLen - a.itemsLen)}
        items={items}
      />
    </Layout>
  );
};

export default Catalog;

// Should return one item (with images) for each mal taxa
// We used to filter this based off of taxas that had no parent
// (so, root taxa) but latest API changes 4/27/2020
// have made it so that all parent ids are now null
export const query = graphql`
  query catalogTaxa {
    allMalTaxa {
      nodes {
        name
        slug
        itemImage
        itemsLen
      }
    }
    allMalItem {
      nodes {
        name
        slug
        displayImage
      }
    }
  }
`;
